export function includesIgnoringCase(testValue) {
  const testValueLower = testValue.toLowerCase();

  return cellValue =>
    cellValue !== null && cellValue.toString().toLowerCase().includes(testValueLower);
}

export function applyColumnFilters(rows, columns, filters = {}) {
  const filterEntries = Object.entries(filters);

  if (filterEntries.length === 0) {
    return rows;
  }

  // initialize custom filter functions per column, or default
  const filterTestFns = {};
  for (const [colKey, value] of filterEntries) {
    const col = columns.find(col => col.dataKey === colKey);
    const filterFn = col.filterFn || includesIgnoringCase;
    filterTestFns[colKey] = filterFn(value);
  }

  const filterCols = Object.keys(filters);

  return rows.filter(row => {
    return filterCols.every(colKey => {
      const filterTestFn = filterTestFns[colKey];
      const filterValue = filters[colKey];
      const value = row[colKey];

      try {
        return filterTestFn(value);
      } catch (ex) {
        console.error(
          `Error filtering rows: could not apply filter test to column '${colKey}', subject '${value}' filter value '${filterValue}'`
        );
        return false;
      }
    });
  });
}

export function applyTextSearch(rows, columns, searchText) {
  if (!searchText) {
    return rows;
  }

  const testFn = includesIgnoringCase(searchText);

  return rows.filter(row => {
    for (const column of columns) {
      const value = row[column.dataKey];
      const valueAsText = column.searchTextFn
        ? column.searchTextFn(value)
        : column.renderFn
        ? column.renderFn(value, row)
        : value;
      if (value !== null && value !== undefined && testFn(valueAsText, searchText)) {
        return true;
      }
    }
    return false;
  });
}

export function sortByColumn(rows, columns, sortBy, sortDirection, tableSortFn) {
  let warnedMissingSortFn = false;

  rows = rows ? [...rows] : [];

  if (!sortBy || !sortDirection) {
    return rows;
  }

  // use sort function for this column if supplied, else use default:
  const colProps = columns.find(col => col.dataKey === sortBy);
  // column does not exist (this happens if the sortBy column is removed)
  if (!colProps) {
    return rows;
  }

  const sortFn =
    colProps.sortFn instanceof Function
      ? (a, b) => colProps.sortFn(a[sortBy], b[sortBy])
      : tableSortFn instanceof Function
      ? (a, b) => tableSortFn(a[sortBy], b[sortBy])
      : (a, b) => {
          if (colProps.numeric) {
            // numeric
            return a[sortBy] - b[sortBy];
          } else {
            // strings
            let strA = a[sortBy] || ""; // ensure defined
            let strB = b[sortBy] || "";

            // verify type / attempt string:
            if (strA.constructor !== String || strB.constructor !== String) {
              // allow simple arrays to sort as if string:
              if (strA.constructor === Array || strB.constructor === Array) {
                strA = (strA || []).join();
                strB = (strB || []).join();
              } else {
                if (!warnedMissingSortFn) {
                  console.warn(
                    `Please provide your own sort function for complex objects. Column name is '${sortBy}'`
                  );
                  warnedMissingSortFn = true;
                }
                return;
              }
            }

            // ignore upper and lowercase:
            strA = strA.toUpperCase();
            strB = strB.toUpperCase();
            if (strA < strB) {
              return -1;
            }
            if (strA > strB) {
              return 1;
            }
            // strings must be equal
            return 0;
          }
          // JAB: What about dates? If date is ISO string, sort by string works fine. If date is
          // number (or Date obj), sort by number works fine. Otherwise, it's a surprise!
          // Seriously though, if dates are to be formatted in non-ISO way, then keep it a number
          // or Date in the data and supply a cellContentRenderer to format it.
        };

  switch (sortDirection) {
    case "DESC":
      rows.sort((a, b) => sortFn(b, a));
      break;
    case "ASC":
    default:
      rows.sort((a, b) => sortFn(a, b));
      break;
  }
  return rows;
}
