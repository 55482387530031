import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";

import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import LandingPage from "./containers/LandingPage";
import ServerDownPage from "./containers/ServerDownPage";
import Files from "./containers/Files";
import BubblePlotContainer from "./containers/BubblePlotContainer";
import RxDiag from "./containers/RxDiag";
import Devices from "./containers/Devices";
import Animals from "./containers/Animals";
import Deployments from "./containers/Deployments";
import Home from "./containers/Home";
import ADSTPage from "./containers/ADSTPage";
import DetectionAnalysis from "./containers/DetectionAnalysis";
import Detections from "./containers/Detections";

import PrivateRoute from "./components/auth/PrivateRoute";

import Amplify from "@aws-amplify/core";

import { AuthConfig } from "./config";

import NotFound from "./components/common/NotFound";

import Users from "./containers/admin/Users";
import AllWorkspaces from "./containers/admin/AllWorkspaces";
import Analytics from "./containers/admin/Analytics";

import Workspaces from "./containers/Workspaces";
import SignInPage from "./containers/SignInPage";
import ForgotPasswordPage from "./containers/ForgotPasswordPage";
import SignUpPage from "./containers/SignUpPage";
import EmailVerificationPage from "./containers/EmailVerificationPage";
import Live2 from "./containers/admin/Live2";

Amplify.configure(AuthConfig);

const reload = () => window.location.reload();

class Routes extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { history } = this.props;

    // Check if the user clicked an invite link. If so, store the data and redirect to workspace.
    // If the user is signed in, NavBar will check local storage and open the invite dialog.
    // If user is not signed in, will automatically redirect to sign in with some messaging.
    // Sign in will check local storage and will inform the user of invite.
    const workspaceInvitationClicked = history.location.pathname.startsWith("/accept-ws-invite");
    if (workspaceInvitationClicked) {
      const qParams = new URLSearchParams(history.location.search);
      const workspaceInviteId = qParams.get("id");
      const workspaceInviteEmail = qParams.get("email");

      localStorage.setItem("workspaceInviteId", workspaceInviteId);
      localStorage.setItem("workspaceInviteEmail", workspaceInviteEmail);
      history.push("/workspaces");
    }
  }

  render() {
    return (
      <Switch>
        <Route path="/learn" exact component={LandingPage} />
        <Route path="/signin" exact component={SignInPage} />
        <Route path="/signup" exact component={SignUpPage} />
        <Route path="/email-verify" exact component={EmailVerificationPage} />
        <Route path="/forgot-password" exact component={ForgotPasswordPage} />
        <PrivateRoute path="/files" exact useStudy component={Files} />
        <PrivateRoute path="/animals" exact useStudy component={Animals} />
        <PrivateRoute path="/devices" exact useStudy component={Devices} />
        <PrivateRoute path="/deployments" exact useStudy component={Deployments} />
        <PrivateRoute path="/home" exact useStudy component={Home} />
        <PrivateRoute path="/detection-map" exact requireStudy component={BubblePlotContainer} />
        <PrivateRoute path="/rx-diag" exact useStudy component={RxDiag} />
        <PrivateRoute path="/detection-analysis" exact useStudy component={DetectionAnalysis} />
        <PrivateRoute path="/detections" exact useStudy component={Detections} />
        <PrivateRoute path="/workspaces" exact component={Workspaces} />
        <Route path="/" exact render={() => <Redirect to="/home" />} />
        <PrivateRoute path="/admin" admin exact component={() => <Redirect to="/admin/users" />} />
        <PrivateRoute path="/admin/users" admin exact component={Users} />
        <PrivateRoute path="/admin/workspaces" admin exact component={AllWorkspaces} />
        <PrivateRoute path="/admin/analytics" admin exact component={Analytics} />
        <PrivateRoute path="/admin/live-v2" admin exact component={Live2} />
        <PrivateRoute path="/error" exact component={ServerDownPage} />
        <Route path="/adst" exact component={ADSTPage} />
        <Route component={NotFound} />
        <Route path="/.well-known/apple-app-site-association" onEnter={reload} />
      </Switch>
    );
  }
}

Routes.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Routes);
