import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import userReducer from "./user/user-reducer";
import filesReducer from "./files/files-reducer";
import studyReducer from "./study/study-reducer";
import snackbarReducer from "./snackbar/snackbar-reducer";
import rxDiagReducer from "./rxdiag/rxdiag-reducers";
import bubblePlotReducer from "./bubbleplot/bubbleplot-reducer";
import devicesReducer from "./devices/devices-reducer";
import animalsReducer from "./animals/animals-reducer";
import deploymentsReducer from "./deployments/deployments-reducer";
import workspaceReducer from "./workspace/workspace-reducer";
import detectionReducer from "./detections/detection-reducer";
import adminUserReducer from "./admin/user/admin-user-reducer";
import adminWorkspaceReducer from "./admin/workspace/admin-workspace-reducer";
import adminAnalyticsReducer from "./admin/analytics/admin-analytics-reducer";
import adminLive2 from "./admin/live2/live-2-reducer";
import detDiagReducer from "./detection-analysis/detection-analysis-reducers";
import { networkReducer } from "./network/network-reducer";
import { ROOT_RESET_WORKSPACE } from "./action-types";

const middleware = applyMiddleware(thunk);

// Only want redux dev tools development environment
let composeEnhancers;
if (process.env.NODE_ENV === "development") {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const appReducer = combineReducers({
  user: userReducer,
  files: filesReducer,
  study: studyReducer,
  snackbar: snackbarReducer,
  rxdiag: rxDiagReducer,
  bubbleplot: bubblePlotReducer,
  devices: devicesReducer,
  animals: animalsReducer,
  deployments: deploymentsReducer,
  workspaces: workspaceReducer,
  detection: detectionReducer,
  detdiag: detDiagReducer,

  adminUsers: adminUserReducer,
  adminWorkspaces: adminWorkspaceReducer,
  adminAnalytics: adminAnalyticsReducer,
  adminLive2: adminLive2,

  network: networkReducer,
});

const rootReducer = (state, action) => {
  /**
   * When the user changes workspace this clears all workspace related data
   * this is much easier that calling actions from each of the sub types
   */
  if (action.type === ROOT_RESET_WORKSPACE) {
    state = {
      user: state.user,
      workspaces: state.workspaces,
    };
  }
  return appReducer(state, action);
};

// create the store
const store = createStore(rootReducer, composeEnhancers(middleware));

export type RootState = ReturnType<typeof appReducer>;
export default store;
