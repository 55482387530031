import { FileInfo, FilesAction } from "./files-types";

type FilesState = {
  fileList: FileInfo[];
  isLoaded: boolean;
  downloads: Record<string, FileDownload>;
};

type FileDownload = {
  name: string;
  progress: number;
};

const initialState: FilesState = {
  fileList: [],
  isLoaded: false,
  downloads: {},
};

export default function filesReducer(
  state: FilesState = initialState,
  action: FilesAction
): FilesState {
  switch (action.type) {
    case "FILES_SET_LIST":
      return {
        ...state,
        isLoaded: true,
        fileList: action.payload.fileList,
      };
    case "FILE_DOWNLOAD_START_PROGRESS": {
      const { downloads } = state;
      downloads[action.payload.key] = { name: action.payload.fileName, progress: 0 };
      return {
        ...state,
        downloads,
      };
    }
    case "FILE_DOWNLOAD_END_PROGRESS": {
      const { downloads } = state;
      delete downloads[action.payload.key];
      return {
        ...state,
        downloads: { ...downloads },
      };
    }
    case "FILE_DOWNLOAD_UPDATE_PROGRESS": {
      const { downloads } = state;
      if (downloads[action.payload.key]) {
        downloads[action.payload.key].progress = action.payload.progress;
        return {
          ...state,
          downloads: { ...downloads },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
